import React from "react";
import {Stack} from "@chakra-ui/react";
import dynamic from "next/dynamic";
const Certificates = dynamic(() => import("@/components/certificates"));
const ContactForm = dynamic(() => import("@/components/contactForm"));
const Customers = dynamic(() => import("@/components/customers"));
const Features = dynamic(() => import("@/components/features"));
const SafeView = dynamic(() => import("@/components/safeView"));

type Props = {
  children: React.ReactNode;
  marginTop?: number;
  showCertificates?: boolean;
  showContactForm?: boolean;
  showCustomers?: boolean;
  showFeatures?: boolean;
  spacing?: number;
};

const PageLayout = (props: Props) => {
  const {
    children,
    marginTop,
    showCertificates,
    showContactForm,
    showCustomers,
    showFeatures,
    spacing = 12,
  } = props;
  return (
    <SafeView>
      <Stack marginTop={marginTop} spacing={spacing}>
        {children}
        {showFeatures && <Features />}
        {showCertificates && <Certificates />}
        {showContactForm && <ContactForm />}
        {showCustomers && <Customers />}
      </Stack>
    </SafeView>
  );
};

export default PageLayout;
