export const MAIN_ROUTES = {
  ABOUT_US: "/about-us",
  ACCEPTED_USE_POLICY: "./accepted-use-policy",
  ADVANCE_NETWORK: "/advance-network",
  BAREMETAL: "/baremetal",
  CATALOG: "/catalog",
  CLOUD_COMPUTE: "/cloud-compute",
  COOKIE_POLICIES: "/cookie-policies",
  CONTACT_US: "/contact-us",
  COVID_ADVISORY: "./covid-19-advisory",
  DATA_CENTER: "/data-center",
  HOME: "/",
  INDEMNITY_AGREEMENT: "./indemnity-agreement",
  IPV4_REQUEST_FORM: "./ipv4-request-form",
  OPTIMIZED_CLOUD_COMPUTE: "/optimized-cloud-compute",
  PARTNERS: "/partners",
  PRICING: "/pricing",
  PRIVACY_POLICIES: "/privacy-policies",
  SERVICES: "/services",
  SERVICE_TERM: "./911-service-terms",
  TERM_CONDITION: "/terms-and-conditions",
  VOIP_AGREEMENT: "./voip-agreement",
};

export const SERVICES_ROUTES = {
  BUSINESS_RISK_SERVICE: "/business-risk-service",
  CLOUD_SERVICE: "/cloud-service",
  IOT: "/iot",
  INTERNET_SERVICE: "/internet-services",
  PROFESSIONAL_SERVICES: "/professional-services",
  VOICE_SERVICES: "/voice-services",
};

export const VOICE_SERVICE_ROUTES = {
  CLOUD_FAX: "/cloud-fax",
  CLOUD_HOSTED_PBX: "/cloud-hosted-pbx",
  UNIFIED_COMMUNICATIONS: "/unified-communications",
};

export const CLOUD_SERVICE_ROUTES = {
  CLOUD_APPLICATION: "/cloud-application",
  CLOUD_STRATEGY: "/cloud-strategy",
  OBSERVABILITY: "/observability",
};

export const BUSINESS_RISK_SERVICES_ROUTES = {
  DRAAS: "/disaster-recovery",
  INFOSEC_TRAINING: "/infosec-training",
  SECURITY_TECHNOLOGIES: "/security-technologies",
};

export const INTERNET_SERVICES_ROUTES = {
  BUSINESS_INTERNET: "/business-internet",
  DATA_CENTER_CONNECTIVITY: "/datacenter-connectivity",
  IP_TV_SOLUTIONS: "/ip-tv-solutions",
};

export const VOICE_SERVICES_ROUTES = {
  CLOUD_FAX: "/cloud-fax",
  CLOUD_HOSTED_PBX: "/cloud-hosted-pbx",
  UNIFIED_COMMUNICATIONS: "/unified-communications",
};
