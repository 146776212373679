import React from "react";
import Image, {StaticImageData} from "next/image";
import Link from "next/link";
import {Box, SimpleGrid, Stack, Text, VStack} from "@chakra-ui/react";
import Title from "@/components/title";
import {CLOUD_SERVICES, HOME_CONSTANTS} from "../home.constants";

const CloudServices = () => {
  return (
    <VStack spacing={8}>
      <Title highlight="Services" title={HOME_CONSTANTS.CLOUD_SERVICES} />
      <SimpleGrid
        columns={{base: 1, sm: 2}}
        spacing={6}
        w={{base: "full", md: "80%", lg: "70%"}}
      >
        {CLOUD_SERVICES.map((item, index) => {
          return <CloudServiceCard key={index} {...item} />;
        })}
      </SimpleGrid>
    </VStack>
  );
};

export default CloudServices;

type Props = {description: string; logo: StaticImageData; path: string; title: string};

const CloudServiceCard = (props: Props) => {
  const {description, logo, path, title} = props;
  return (
    <Box
      as={Link}
      borderColor="gray.400"
      borderRadius="8px"
      borderWidth={"1px"}
      boxShadow="lg"
      href={path}
      justifyContent={"space-between"}
      px={6}
      py={8}
      target={"_blank"}
    >
      <Stack spacing={6} w="full">
        <Box position="relative" h="100px" w="full">
          <Image
            alt={title}
            title={title}
            fill
            placeholder="blur"
            src={logo}
            sizes="220px"
            style={{objectFit: "contain", objectPosition: "center"}}
          />
        </Box>
        <Text textAlign={"center"}>{description}</Text>
      </Stack>
    </Box>
  );
};
