import Image, {StaticImageData} from "next/image";
import Link from "next/link";
import {AspectRatio, Box, Center, Heading, SimpleGrid, VStack} from "@chakra-ui/react";
import {INTRO_CARDS_LIST} from "../home.constants";

const IntroCards = () => {
  return (
    <SimpleGrid
      columns={{base: 1, sm: 2, md: 4}}
      position={"relative"}
      spacing={6}
      top={{base: -24, sm: -28}}
      zIndex={2}
    >
      {INTRO_CARDS_LIST.map((item, index) => {
        return <IntroCard key={index} {...item} />;
      })}
    </SimpleGrid>
  );
};

export default IntroCards;

type Props = {
  image: StaticImageData;
  isExternal: boolean;
  path: string;
  title: string;
};

const IntroCard = (props: Props) => {
  const {image, isExternal, path, title} = props;
  return (
    <Box
      as={Link}
      backgroundColor={"white"}
      boxShadow="lg"
      href={path}
      padding={{base: 4, md: 6}}
      target={isExternal ? "_blank" : undefined}
    >
      <VStack spacing={4}>
        <Center borderColor="blue.700" borderRadius="full" borderWidth="1px" p={2}>
          <Center backgroundColor={"gray.200"} borderRadius="full" h="100px" w="100px">
            <AspectRatio position="relative" w="80px">
              <Image alt={title} fill src={image} style={{objectFit: "contain"}} />
            </AspectRatio>
          </Center>
        </Center>
        <Heading as="h3" size="md" textAlign="center">
          {title}
        </Heading>
      </VStack>
    </Box>
  );
};
