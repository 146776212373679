"use client";
import {useEffect, useRef, useState} from "react";
import {Box, Center, Heading} from "@chakra-ui/react";
import {INTRO_CONSTANTS} from "../home.constants";
import {HEADING_VARIANTS} from "@/theme/components/heading";

const Intro = () => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const [currentHeadingIndex, setCurrentHeadingIndex] = useState(0);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCurrentHeadingIndex((prevIndex) => (prevIndex + 1) % INTRO_CONSTANTS.length);
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <Box
      backgroundImage={"/home/backdrop.jpeg"}
      backgroundPosition={"center"}
      backgroundRepeat={"no-repeat"}
      backgroundSize="cover"
    >
      <Center
        backgroundColor="brand.300"
        h="300px"
        opacity="0.8"
        px={{base: 4, sm: 8}}
        py={{base: 4, sm: 12}}
        w="full"
      >
        <Heading color="white" textAlign={"center"} variant={HEADING_VARIANTS.HEADLINE2}>
          {INTRO_CONSTANTS[currentHeadingIndex]}
        </Heading>
      </Center>
    </Box>
  );
};

export default Intro;
