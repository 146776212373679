"use client";
import {Box, Stack} from "@chakra-ui/react";
import PageLayout from "@/layouts/pageLayout";
import CloudServices from "./components/cloudServices";
import ExpertiseCards from "./components/expertiseCards";
import ExpertiseContents from "./components/expertiseContents";
import ExpertiseGlobe from "./components/expertiseGlobe";
import Intro from "./components/intro";
import IntroCards from "./components/introCards";

const Homepage = () => {
  return (
    <Stack spacing={12}>
      <Intro />
      <PageLayout showCertificates showContactForm showCustomers showFeatures>
        <Box>
          <IntroCards />
          <ExpertiseContents />
        </Box>
        <ExpertiseGlobe />
        <CloudServices />
        <ExpertiseCards />
      </PageLayout>
    </Stack>
  );
};
export default Homepage;
