import Application from "public/home/ui-design.png";
import Delivery from "public/home/delivery-banner.jpeg";
import CMDCLogo from "public/home/cmdc-logo.png";
import CMLLogo from "public/home/cml-logo.png";
import Innovative from "public/home/innovation.jpeg";
import NextGenTech from "public/home/next.jpeg";
import Pedigree from "public/home/pedigree.jpeg";
import Shield from "public/home/shield.png";
import {
  BUSINESS_RISK_SERVICES_ROUTES,
  CLOUD_SERVICE_ROUTES,
  MAIN_ROUTES,
  SERVICES_ROUTES,
} from "@/constants/routes";

export const HOME_CONSTANTS = {
  CLOUD_SERVICES: "Our Cloud Services",
};

export const INTRO_CONSTANTS = [
  "Next Gen Technology",
  "Innovation",
  "Pedigree",
  "Delivery",
];

export const INTRO_CARDS_LIST = [
  {
    image: CMDCLogo,
    isExternal: true,
    path: "https://cloudmydc.com/",
    title: "Public Cloud",
  },
  {
    image: CMLLogo,
    isExternal: true,
    path: "https://cloudmylab.com/",
    title: "POC Cloud",
  },
  {
    image: Shield,
    isExternal: false,
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.BUSINESS_RISK_SERVICE}${BUSINESS_RISK_SERVICES_ROUTES.SECURITY_TECHNOLOGIES}`,
    title: "Security Technology",
  },
  {
    image: Application,
    isExternal: false,
    path: `${MAIN_ROUTES.SERVICES}${SERVICES_ROUTES.CLOUD_SERVICE}${CLOUD_SERVICE_ROUTES.CLOUD_APPLICATION}`,
    title: "Application Service",
  },
];

export const CLOUD_SERVICES = [
  {
    description:
      "Cloudmylab is the World’s First Cloud-based Proof Concept Platform and the only EVE-NG Premier Partner in the United States. Cloudmylab hosts over 2500 active Customers worldwide, including some Fortune 500 customers and United States Government.",
    logo: CMLLogo,
    path: "https://cloudmylab.com/",
    title: "CloudMyLab",
  },
  {
    description:
      "Cloudmydc, a subsidiary of Onemind Services, specializes in providing cost-effective Infrastructure-as-a-Service (IaaS) and Platform-as-a-Service (PaaS) solutions, catering to both public and private cloud needs for businesses seeking flexibility and scalability.",
    logo: CMDCLogo,
    path: "https://cloudmydc.com/",
    title: "CloudMyDC",
  },
];

export const EXPERTISE = [
  {
    description:
      "Our competent team is trained in delivering high standards of technical competence. Our engineers have extensive knowledge/massive experience across various IT skills which enable them to deliver their best services to our clients.",
    image: Pedigree,
    title: "Pedigree",
  },
  {
    description:
      "'Innovation distinguishes between a leader and a follower' as Steve Jobs says. We bring innovation to your business which distinguishes you from your competitors, helps you scale faster, optimize your investments and realize your goals.",
    image: Innovative,
    title: "Innovative",
  },
  {
    description:
      "Our strength has been our intellectual acuity and flexibility to align with our clients in understanding their requirements and delivering to their expectations. Our team is focused and attuned to deliver results.",
    image: Delivery,
    title: "Delivery",
  },
  {
    description:
      "With the accelerating pace of technological innovations, older technologies and processes are steadily falling behind the speed and power curve. Today’s business requires IT that is agile and nimble to keep up with today’s demands.",
    image: NextGenTech,
    title: "Next Gen Technology",
  },
];

export const EXPERTISE_GLOBE_CONSTANTS = [
  "True In-Country Telephony Services",
  "Contact Center",
  "Customer Support",
  "International Reach and Presence",
  "Cost Saving",
  "Business Continuity",
];
export const GLOBE_PARA_CONSTANTS = [
  "Emergency services,in-country toll free, portability and local presence regardless of geography.",
  "Omni-channel communication and business analytics.",
  "24*7*365 Support-MACD Support.",
  "No once can offer the same reach as OneMind Services.",
  "No equipment/servers and no maintenance costs.",
  "",
];
export const EXPERTISE_CONTENT_CONSTANTS = {
  EXPERTISE_HEADING: "Our Expertise | Your Success",
  MAIN_HEADING:
    "We are a Full-Service MSP and CSP focused on Cloud, Security, IoT, and Datacenter Technologies.",
  MAIN_TEXT:
    "We align our expertise and experience with the goals of our customers to help them build stronger and more innovative businesses. Investment in our people, process, and leading technologies have resulted in offerings for our customers to realize their objectives.",
};
