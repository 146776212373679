import Image, {StaticImageData} from "next/image";
import {Box, Heading, Text, SimpleGrid, Stack} from "@chakra-ui/react";
import {EXPERTISE} from "../home.constants";

const ExpertiseCards = () => {
  return (
    <SimpleGrid columns={{base: 1, sm: 2}} spacing={6}>
      {EXPERTISE.map((item, index) => (
        <ExpertiseCard
          description={item.description}
          image={item.image}
          key={index}
          title={item.title}
        />
      ))}
    </SimpleGrid>
  );
};

export default ExpertiseCards;

type Props = {
  description: string;
  image: StaticImageData;
  title: string;
};

const ExpertiseCard = (props: Props) => {
  const {image, title, description} = props;
  return (
    <Box
      borderWidth="1px"
      borderColor="gray.400"
      borderRadius="5px"
      boxShadow="lg"
      overflow="hidden"
      _hover={{
        img: {
          transform: "scale(1.04)",
          transition: "all 0.3s linear",
        },
      }}
    >
      <Box width={"100%"} overflow={"hidden"}>
        <Image alt={title} src={image} style={{width: "100%"}} />
      </Box>
      <Stack p={{base: 4, sm: 6, md: 8}} spacing={4}>
        <Heading color="brand.100">{title}</Heading>
        <Text lineHeight="20px">{description}</Text>
      </Stack>
    </Box>
  );
};
