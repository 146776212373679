"use client";
import {useState, useEffect, useRef} from "react";
import {AspectRatio, Center, Heading, Text, VStack} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {EXPERTISE_GLOBE_CONSTANTS, GLOBE_PARA_CONSTANTS} from "../home.constants";

const ExpertiseGlobe = () => {
  const headingIntervalRef = useRef<NodeJS.Timeout>();
  const paraIntervalRef = useRef<NodeJS.Timeout>();
  const [globeHeading, setGlobeHeading] = useState(0);
  const [globeParaHeading, setGlobeParaHeading] = useState(0);

  useEffect(() => {
    headingIntervalRef.current = setInterval(() => {
      setGlobeHeading((prevIndex) => (prevIndex + 1) % EXPERTISE_GLOBE_CONSTANTS.length);
    }, 8000);
    paraIntervalRef.current = setInterval(() => {
      setGlobeParaHeading((prevIndex) => (prevIndex + 1) % GLOBE_PARA_CONSTANTS.length);
    }, 8000);

    return () => {
      clearInterval(headingIntervalRef.current);
      clearInterval(paraIntervalRef.current);
    };
  }, []);

  return (
    <Center>
      <AspectRatio
        backgroundImage={"/home/globe.png"}
        backgroundPosition={"center"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        ratio={1024 / 1007}
        w={{base: "full", sm: "550px"}}
      >
        <Center h="full" w="full">
          <AspectRatio
            backgroundColor={"rgba(2, 27, 102, 0.4)"}
            borderRadius="full"
            ratio={1024 / 1007}
            w="80%"
          >
            <VStack textAlign="center" p={{base: 2, sm: 4}}>
              <Heading
                color="white"
                maxWidth="250px"
                variant={HEADING_VARIANTS.SUBTITLE1}
              >
                {EXPERTISE_GLOBE_CONSTANTS[globeHeading]}
              </Heading>
              <Text color="white" variant={TEXT_VARIANTS.BODY1}>
                {GLOBE_PARA_CONSTANTS[globeParaHeading]}
              </Text>
            </VStack>
          </AspectRatio>
        </Center>
      </AspectRatio>
    </Center>
  );
};

export default ExpertiseGlobe;
