import {Heading, Text, VStack} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {EXPERTISE_CONTENT_CONSTANTS} from "../home.constants";
const ExpertiseContents = () => {
  return (
    <VStack spacing={4}>
      <Heading color="brand.100" variant={HEADING_VARIANTS.SUBTITLE1}>
        {EXPERTISE_CONTENT_CONSTANTS.EXPERTISE_HEADING}
      </Heading>
      <Heading textAlign="center" variant={HEADING_VARIANTS.HEADLINE2}>
        {EXPERTISE_CONTENT_CONSTANTS.MAIN_HEADING}
      </Heading>
      <Text textAlign="center" variant={TEXT_VARIANTS.BODY2}>
        {EXPERTISE_CONTENT_CONSTANTS.MAIN_TEXT}
      </Text>
    </VStack>
  );
};
export default ExpertiseContents;
